.show-service-class-title {
    /* margin-top: 100px;
    margin-bottom: 50px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    background-color: #1b1b1bd9;
    border-radius: 20px;
    margin-top: 25px;
    /* padding-bottom: 10px; */
}

p .icon {
    height: 16px;
    width: 16px;
}