.pass-wrapper2 {
    position: center;
    display: flex;
    margin-bottom: 14px;
}

i {
    position: absolute;
    top: 20%;
    right: 1%;
}

i:hover {
    color: #535050;
    cursor: pointer;
}
