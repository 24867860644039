.outDiv {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}

.mainTitle {
    size: 100px;
    font-weight: 500px;
    text-align: center;
    margin-bottom: 20px;
}

.formStyle {
    width: 70%;
    height: 100%;
    min-width: 500px;
    display: flexbox;
    border-radius: calc(0.5 * 100px);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);
    border: solid rgba(0, 0, 0, 0);
    border-width: 40px;
    align-items: center;
}

.formLable {
    height: 35px;
    color: black;
    font-weight: 500;
    float: inline-start;
    margin-bottom: 10px;
}

.inputBox {
    width: 40%;
    height: 35px;
    border-radius: 5px;
    float: right;
    padding: 3px 7px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    outline: none;
    margin-bottom: 10px;
}

.selectBox {
    width: 40%;
    height: 35px;
    border-radius: 5px;
    float: right;
    box-sizing: border-box;
    outline: none;
    margin-bottom: 10px;
}

.selectBoxLable {
    width: 50%;
    height: 35px;
    border-radius: 5px;
    float: left;
    padding: 3px 7px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    outline: none;
    margin-bottom: 10px;
}

.selectStyle {
    width: 50%;
    height: 35px;
    border-radius: 5px;
    float: left;
    padding: 3px 7px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    outline: none;
    margin-bottom: 10px;
}

.buttonStyle {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 30px;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    vertical-align: middle;
}

.attributeInput {
    width: 20% !important;
    float: right !important;
}

.attributeLable {
    width: 60% !important;
}

.attributeInputTitle {
    width: 20% !important;
    float: right !important;
    text-align: center;
}

.remove {
    display: none;
}

.background {
    /* min-height: 100vh; */
    /* background-size: contain;
    background-repeat: repeat; */
    background-color: black;
}

.App {
    /* width: 2000vw; */
    position: relative;
    z-index: 0;
    display: flex;
    font-size: calc(10px + 2vmin);
    flex-direction: column;
    padding: 0px;
    color: white;
    justify-content: center;
}

.custom-btn {
    background-color: transparent !important;
    border-color: white !important;
}

.custom-btn:hover {
    border-color: white !important;
    background-color: gray !important;
}

.card-text {
    font-size: 1rem;
    margin-top: 1rem;
}

.pricing-card {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px !important;
}

.manualButton {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 80px;
    margin-right: 5%;
    background-color: rgb(128, 0, 0) !important;
    border: none !important;
    cursor: pointer;
}

.accountButton {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 80px;
    margin-right: 5%;
    background-color: rgb(128, 0, 0) !important;
    border: none !important;
    cursor: pointer;
}
  
  