.Footer {
    /* margin-top: auto; */

    background-color: rgba(255, 255, 255, 0.7);
    color: #424242;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 25px 0;
    width: 100vw;
    bottom: 0;
    left: 0;
    position: absolute;
    text-align: center;
    font-weight: 500;
}
  